import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Slider from 'react-slick';
import styled from 'styled-components';
import { FaGamepad, FaChartLine, FaPalette, FaMobileAlt, FaChartPie } from 'react-icons/fa';

const Features = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Show 4 cards at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <div className="container">
        <h1
          className="heading"
          initial={{ x: -100, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            x: { type: 'spring', stiffness: 60 },
            opacity: { duration: 1 },
          }}
        >
          Key Features
        </h1>

        <Slider {...sliderSettings}>
          {featureData.map((feature, index) => (
            <FeatureCard key={index} feature={feature} />
          ))}
        </Slider>
      </div>
    </Wrapper>
  );
};

const FeatureCard = ({ feature }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <motion.div
      className="feature-card"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <AnimatePresence initial={false}>
        {isFlipped ? (
          <motion.div
            className="card-back"
            key="back"
            initial={{ rotateY: -90, opacity: 0 }}
            animate={{ rotateY: 0, opacity: 1 }}
            exit={{ rotateY: 90, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p className="feature-text">{feature.text}</p>
          </motion.div>
        ) : (
          <motion.div
            className="card-front"
            key="front"
            initial={{ rotateY: 90, opacity: 0 }}
            animate={{ rotateY: 0, opacity: 1 }}
            exit={{ rotateY: -90, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="icon">{feature.icon}</div>
            <h3 className="feature-title">{feature.title}</h3>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const featureData = [
  {
    icon: <FaGamepad size={80} color="#3b96c3" />,
    title: 'Game CMS',
    text: 'Manage game flow, landing pages, and dynamic content with ease.',
  },
  {
    icon: <FaChartLine size={80} color="#3b96c3" />,
    title: 'Analytics & Dashboards',
    text: 'Track campaign performance live with detailed dashboards.',
  },
  {
    icon: <FaPalette size={80} color="#3b96c3" />,
    title: 'Brand Design',
    text: 'Maintain brand consistency with custom fonts and colors.',
  },
  {
    icon: <FaMobileAlt size={80} color="#3b96c3" />,
    title: 'Flexible Design',
    text: 'Responsive across devices for a seamless user experience.',
  },
  {
    icon: <FaChartPie size={80} color="#3b96c3" />,
    title: 'Easy Embedding',
    text: 'Integrate with websites, apps, or social media effortlessly.',
  },
];

const Wrapper = styled.section`
  padding: 4rem 2rem;
  background-color: #f4f4f4;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 2.5rem;
    padding-right: 10px;
  }

  .heading {
    font-size: 3rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.heading || '#333'};
    margin-bottom: 3rem;
    text-align: center;
  }

  .feature-card {
    position: relative;
    width: 250px;
    height: 250px;
    perspective: 1000px;
    cursor: pointer;
    border: 1px solid #d3d3d3; /* Lightest border */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow */
    margin: 0 auto;
  }

  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }

  .card-front {
    background-color: #fff;
  }

  .card-back {
    background-color: #3b96c3;
    color: white;
    transform: rotateY(180deg);
    text-align: center;
  }

  .icon {
    margin-bottom: 1rem;
  }

  .feature-title {
    font-size: 2rem;
    font-weight: 400;
    color: black;
  }

  .feature-text {
    font-size: 1.4rem;
    line-height: 1.6;
    color: white;
  }

  .slick-prev,
  .slick-next {
    z-index: 1;
    color: #333;
  }
`;

export default Features;
