import React from 'react';
import styled from 'styled-components';

const Short = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="section-hero-data">
          <h1>
            <span className="hero-heading">Elevate your business with</span>
            <span className="hero-heading-2">ALLIED AXIS</span>
            <span className="hero-subheading">Business Solutions.</span>
          </h1>
        </div>
        <div className="section-hero-button">
          <a href="/contact" className="contact-btn">
            CONTACT US <span className="button-arrow">→</span>
          </a>
        </div>
        <div className="section-hero-image">
          <img className="rotating-image" src="/images/round.png" alt="Circular Graphic" />
        </div>    
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: 5rem 2rem;
  background-color: #1170bf;
  color: white;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 3rem;
    column-gap: 8rem;
  }

  .section-hero-data {
    flex: 1;
    padding: 1.5rem;
  }

  .hero-heading,
  .hero-heading-2 {
    font-size: 3rem;
    font-weight: bold;
    color: white;
    display: block;
  }

  .hero-heading-2 {
    font-size: 4rem;
    margin-top: 13px;
    margin-bottom: -13px;
  }

  .hero-subheading {
    font-size: 3rem;
    font-weight: 600;
    margin-top: 20px;
    color: white;
  }

  .section-hero-image {
    flex: 1;
    text-align: center;
    margin-right: -120px;
  }

  .rotating-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    animation: rotateImage 4s infinite;
  }

  .section-hero-button {
    text-align: center;
    margin-top: 20px;
    padding-right: 90px;
  }

  .contact-btn {
    background-color: white;
    color: #1170bf;
    text-decoration: none;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 1.5rem;
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 10px 8px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
  }

  .button-arrow {
    font-weight: bold;
    font-size: 20px;
  }

  @keyframes rotateImage {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-90deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    75% {
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-180deg);
    }
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;

    .container {
      flex-direction: row;
      text-align: center;
    }

    .section-hero-data {
      padding: 1rem;
    }
     .hero-heading {
      font-size: 2.5rem;
    font-weight: bold;
    color: white;
    display: block;
    padding-right: 65px;
    width: 394px;
  }
    .hero-heading-2 {
    font-size: 2.5rem;
    padding-right: 241px;
    }

    .hero-subheading {
      font-size: 2.5rem;
    }

    .contact-btn {
      font-size: 1.2rem;
      padding: 8px 16px;
    }

    .rotating-image {
      width: 150px;
      height: 150px;
    }
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
      .hero-heading {
      font-size: 2rem;
    font-weight: bold;
    color: white;
    display: block;
    padding-right: 65px;
    width: 394px;
  }
    .hero-heading-2 {
    font-size: 2.5rem;
    padding-right: 241px;
    margin-bottom: -63px;
    }

    .hero-subheading {
      font-size: 2rem;
       margin-left: 118px;
    }
     .section-hero-button {
    text-align: center;
   margin-top: 20px;
    padding-right: 90px;
    padding-left: 11px;
  }
    .contact-btn {
      font-size: 1rem;
      padding: 8px 12px;
    }
    .section-hero-image {
    flex: 1;
    text-align: center;
    margin-top: -75px;
    padding-left: 72px;
  }
    .rotating-image {
      width: 120px;
      height: 120px;
    }
  }
`;

export default Short;
