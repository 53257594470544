import React from 'react';
import styled from 'styled-components';

const HeroSection = () => {
  return (
    <Wrapper>
      <div className="container grid grid-two-column">
        <div className="section-hero-data">
          <p className="hero-top-data">
            {/* Add any top data here */}
          </p>
          <h1 className="hero-heading">ALLIED AXIS</h1>
          <h4 className="h4-heading">Business Solutions</h4>
          <p className="hero-para">
            This company develops software, provides tech solutions, manages systems, and supports businesses with technology and digital transformation services.
          </p>
        </div>

        <div className="section-hero-image">
          <picture>
            <img src="./images/hero2.jpg" alt="Hero" />
          </picture>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding: 5rem 2rem;
  background-image: url('/images/rotate.jpg'); 
  background-size: cover; 
  background-position: center;

  .container {
    position: relative;
    z-index: 2;
  }

  .section-hero-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    padding-left: 50px;
  }

  .hero-top-data {
    padding: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.helper};
  }

  .hero-heading {
    text-transform: uppercase;
    font-size: 6rem;
    padding-right: 2rem;
    margin: 0;
    padding: 1rem;
    margin-left: -21px;
    color: ${({ theme }) => theme.colors.heading}; 
  }

  .h4-heading {
    font-size: 3rem;
    margin-left: -12px;
    font-weight: bold;
    color: rgb(24 24 29)
    margin: -5px;
    padding: 0rem ;
  }

  .hero-para {
    padding: 1.5rem;
    color: ${({ theme }) => theme.colors.text}; 
    margin-top: 1.5rem;
    margin-bottom: 2.4rem;
    padding-left: 0px;
    margin-left: -11px;
    max-width: 60rem;
    font-size: 1.5rem;
    line-height: 1.6;
    font-family: 'Raleway', sans-serif;
  }

  .section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  picture {
    text-align: center;
  }

  img {
    max-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem 1rem;

    .container {
      grid-template-columns: 1fr;
    }

    .hero-heading {
      font-size: 4.2rem;
    }
      
    .h4-heading {
    font-size: 3rem;
    
    font-weight: bold;
    color: rgb(24 24 29)
    margin: -5px;
    padding: 0rem 1px;
  }
    .hero-para {
      font-size: 1.5rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }

    .section-hero-data {
      display: block;
    }

    img {
      display: none;
    }
  }
`;

export default HeroSection;
