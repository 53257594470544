import React from "react";
import styled from "styled-components";
import { Button } from "../styles/Button";
import { NavLink } from "react-router-dom";
import { FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <Wrapper>
      <section className="contact-short">
        <div className="grid grid-two-column">
          <div className="para2">
            <h3>Ready to get started? <br />Start your journey with us!</h3>
          </div>
          <div className="contact-short-btn">
            <NavLink to="/contact">
              <Button className="button">Get Started</Button>
            </NavLink>
          </div>
        </div>
      </section>

      <footer>
        <div className="container grid grid-four-column">
          <div className="footer-about">
            <h3>Allied Axis Business Solutions</h3>
            <p className="para">
              We are committed to transforming loyalty programs into next-gen interactive customer experience programs.
            </p>
          </div>

          {/* Quick Links Section */}
          <div className="footer-quick-links">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <NavLink className="navbar-link" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="navbar-link" to="/about">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink className="navbar-link" to="/services">
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink className="navbar-link" to="/contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="footer-social--icons">
              <div>
                <a href="#" target="" rel="">
                  <FaTwitter className="icons" />
                </a>
              </div>
              <div>
                <a href="#" target="" rel="">
                  <FaLinkedin className="icons" />
                </a>
              </div>
              <div>
                <a href="#" target="" rel="">
                  <FaEnvelope className="icons" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-contact">
            <h3>Call Us</h3>
            <h2>+91 9205800782</h2>
          </div>
        </div>

        <div className="footer-bottom--section">
          <hr />
          <div className="container grid grid-two-column">
            <p>@{new Date().getFullYear()} Allied Axis. All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </Wrapper>
  );
};

// Wrapper with added styles for "Quick Links"
const Wrapper = styled.section`
  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);
    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }

    h3 {
      font-size: 1.5rem;
    }
  }

  .contact-short-btn {
    justify-self: end;
    align-self: center;

    .button {
      transition: background-color 0.3s ease;
      background-color: rgb(17 112 191);
      &:hover {
        background-color: #ADD8E6;
      }
    }
  }

  footer {

    padding: 10rem 0 3rem 0;
     margin-right: 1.3rem;
    background-color: #1170bf;
     
     .container.grid.grid-four-column {
    padding-left: 50px; /* Added padding-left */
  }
    h3 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
      font-size: 1.5rem;
      width: 101%;
    }
      h2 {
      color: ${({ theme }) => theme.colors.hr};
      margin-bottom: 2.4rem;
      font-size: 1.5rem;
      width: 101%;
    }

    p {
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.5rem;
      width: 233px;
    }

    .footer-about {
      p {
        display: block;
      }
    }

    /* New Quick Links Section Styling */
    .footer-quick-links {
        padding-left: 85px;
      h3 {
        color: ${({ theme }) => theme.colors.white};
        margin-bottom: 1rem;
      }

      ul {
        list-style-type: none;
        padding: 0;
      }

      li {
        margin: 0.5rem 0;
      }

      .navbar-link {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        font-size: 1.4rem;
        opacity: 0.8;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .footer-social--icons {
      display: flex;
      gap: 2rem;

      div {
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid ${({ theme }) => theme.colors.white};

        .icons {
          color: ${({ theme }) => theme.colors.white};
          font-size: 2.4rem;
          position: relative;
          cursor: pointer;
        }
      }
    }

    .footer-bottom--section {
      padding-top: 9rem;

      hr {
        margin-bottom: 2rem;
        color: ${({ theme }) => theme.colors.hr};
        height: 0.1px;
      }

      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin: 0;
          margin-left: 441px;
          width: 100%;
        }

        div {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    .contact-short {
      max-width: 80vw;
      padding: 3rem 2rem;
      transform: translateY(30%);
      h3 {
        font-size: 1.5rem;
      }
      .button {
        padding: 0.7rem 1rem;
        font-size: 1rem;
        width: 100px;
      }
    }

    footer {
      padding: 7rem 1rem;
       
      .container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;

        .footer-about {
          text-align: center;
        }

        .footer-contact{
          text-align: center;
        }


        .footer-social {
          text-align: center;
        }

        .footer-social--icons {
          justify-content: center;
          gap: 1rem;
        }
      }
       

      .footer-bottom--section {
        padding-top: 2rem;

        hr {
          margin-bottom: 1rem;
          color: ${({ theme }) => theme.colors.hr};
          height: 0.1px;
        }

        .container {
          flex-direction: row;
          align-items: center;
          text-align: center;
          gap: 1rem;

          p {
            margin: 0;
          }

          div {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .contact-short {
      max-width: 80vw;
      padding: 1rem 1rem;
      transform: translateY(20%);
      .para2 {
        padding: 10px;
      }
      h3 {
        font-size: 1.5rem;
        width: 197px;
      }
        
      .button {
        padding: 0.5rem 0.7rem;
        font-size: 1.5rem;
        width: 84px;
        height: 47px;
        border-radius: 8px;
        margin-left: -89px;
      }
    }

    footer {
      padding: 4rem 1rem;
        h3 {
    color: #ffffff;
    margin-bottom: 1.4rem;
    font-size: 1.5rem;
    width: 101%;
}     
    h2{
    color: #ffffff;
    margin-bottom: 1.4rem;
    font-size: 1.5rem;
    width: 209%;
    opacity: 0.8; 
}
       .container.grid.grid-four-column {
    padding-left: 0px;
  }
      .container {
       display: flex;
      flex-direction: column;
        gap: 1.5rem;
        text-align: center;
        margin-left: 15px;
        .footer-about {

            padding-right: 131px;
          p {
      color: #fff;
    font-size: 1.5rem;
    width: 263px;
        padding-left: 5px;
    text-align: left;
      
    }
          .h3 {
            font-size: 4rem;
            font-weight: 200;
            width: 81px;
            margin-bottom: 1.4rem;
            font-size: 1.5rem;
          }
        }
          .footer-quick-links {
        padding-left: 0px; 
        padding-right: 264px;
      }
        .footer-contact
        {
        text-align: center;
        padding-right: 298px;
        margin-top: -85px;
        }

        .footer-social {
          text-align: center;
          padding-left: 110px;
        padding-top: 0px;
        }

        .footer-social--icons {
          justify-content: center;
          gap: 0.5rem;
          .icons {
            font-size: 15px;
          }
          div {
            padding: 1rem;
            border-radius: 50%;
            border: 0.5px solid #fff;
          }
        }
      }

      .footer-bottom--section {
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-right: 1rem;

        hr {
          margin-bottom: 1rem;
          color: ${({ theme }) => theme.colors.hr};
          height: 0.1px;
        }

        .container {
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 0.5rem;

          p {
            margin: 0;
          }

          div {
            flex-direction: column;
            gap: 0.5rem;
          }
        }
      }

     
    }
  }
`;

export default Footer;
