import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from './styles/Button';
import { motion } from 'framer-motion';
import { GlobalStyle } from './GlobalStyle'; // Import GlobalStyle

const Contact = () => {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    subject: "",
    message: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const SubmitData = async (event) => {
    event.preventDefault();
    const { username, email, subject, message } = userData;
    if (username && email && subject && message) {
      const res = await fetch('https://reactdatabase-69d14-default-rtdb.firebaseio.com/userDataRecords.json', {
        method: "post",
        Headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          email,
          subject,
          message,
        }),
      });

      if (res) {
        setUserData({
          username: "",
          email: "",
          subject: "",
          message: "",
        });
        alert("Data Stored");
      } else {
        alert("Fill the Data");
      }
    } else {
      alert("Fill the Data");
    }
  };

  return (
    <>
      <GlobalStyle /> {/* Apply Global styles here */}
      <Wrapper>
        <h2 className="heading">Feel Free to Contact Us</h2>
        <motion.iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.120984927322!2d77.30820539999999!3d28.4457691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cddf521657297%3A0x15856257cbaf48a5!2sSRS%20Tower%2C%20Sector%2031%2C%20Faridabad%2C%20Haryana%20121003!5e0!3m2!1sen!2sin!4v1726481305201!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        ></motion.iframe>
        <div className="contact-box">
          <div className="container">
            <div className="contact-content">
              <motion.div
                className="contact-image"
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: 'spring', stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: 'easeIn',
                  duration: 1,
                }}
              >
                <h3>Let us talk about</h3>
                <p>Innovative solutions for your business success.</p>
                <img src="./images/contact-Photoroom.png" alt="Contact" />
              </motion.div>
              <motion.div
                className="contact-form"
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 0.2,
                  x: { type: 'spring', stiffness: 60 },
                  opacity: { duration: 1 },
                  ease: 'easeIn',
                  duration: 1,
                }}
              >
                <form action="" method="post" className="contact-inputs">
                  <motion.input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={userData.username}
                    onChange={postUserData}
                    autoComplete="off"
                    required
                    whileFocus={{ scale: 1.05 }}
                  />
                  <motion.input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={postUserData}
                    autoComplete="off"
                    required
                    whileFocus={{ scale: 1.05 }}
                  />
                  <motion.textarea
                    name="subject"
                    cols="20"
                    rows="2"
                    placeholder="Subject"
                    value={userData.subject}
                    onChange={postUserData}
                    autoComplete="off"
                    required
                    whileFocus={{ scale: 1.05 }}
                  ></motion.textarea>
                  <motion.textarea
                    name="message"
                    cols="30"
                    rows="6"
                    placeholder="Your message"
                    value={userData.message}
                    onChange={postUserData}
                    autoComplete="off"
                    required
                    whileFocus={{ scale: 1.05 }}
                  ></motion.textarea>
                  <Button className="btn" onClick={SubmitData}>SEND</Button>
                </form>
              </motion.div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  padding: 3rem 0 5rem 0;

  .container {
    margin-top: 6rem;
    text-align: center;
  }

  .heading {
   font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.heading};
  }

  .contact-box {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 2rem;
  }

  .contact-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 3rem;
    max-width: 1000px;
    margin: auto;
  }

  .contact-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact-image img {
    width: 100%;
    max-width: 400px;
    border-radius: 8px;
    
    margin-top: 1rem;
  }

  .contact-form {
    flex: 1;
  }

  .contact-inputs {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: left;
  }

  .contact-inputs input,
  .contact-inputs textarea {
    width: 100%;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .contact-inputs input:focus,
  .contact-inputs textarea:focus {
    outline: none;
    border-color: #000;
  }

  .contact-inputs textarea {
    resize: vertical;
  }

  .btn {
    align-self: flex-start;
    max-width: 16rem;
    background-color: #039BE5;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #add8e6;
    }
  }

  h3 {
    margin-top: 1rem;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-weight: 600;

  }

  p {
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }
    @media(max-width : 700px) {
    .contact-image{
    display : none;
    }
    }
`;

export default Contact;
