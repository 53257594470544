import React from 'react';
import styled from 'styled-components';
import { GlobalStyle } from './GlobalStyle'; // Adjust the path as necessary

const About = () => {
  return (
    <>
      <GlobalStyle /> {/* Apply Global styles here */}
      <Wrapper>
        <section id='about'>
          <div className="container grid grid-two-column">
            <div className="section-hero-data">
              <h2 className="hero-heading">
                About <span>Us</span>
              </h2>
              <p className="hero-para">
                We are committed to transforming loyalty programs into next-gen interactive customer experience programs.
              </p>
              <p className="hero-para">
                By leveraging the power of gamification, we elevate your existing loyalty or customer engagement programs to new heights. Our innovative approach ensures that your customers remain engaged, motivated, and captivated through interactive experiences.
              </p>
            </div>
            
            <div className="section-hero-image">
              <picture>
                <img src="./images/About.png" alt="about" />
              </picture>
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  padding: 10rem 0.5rem;

  .container {
    max-width: 120rem;
    margin: 0 auto;
    display: grid;
    gap: 9rem;
    padding: 2rem;
  }

  .grid-two-column {
    grid-template-columns: repeat(2, 1fr);
  }

  .section-hero-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .hero-heading {
    font-size: 3rem;
    color: black;
    font-weight: bold;
    letter-spacing: 0.1rem;
    display: inline-block;
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    transition: color 0.3s ease-in-out;

    span {
      color: ${({ theme }) => theme.colors.primary};
    }

    &:after {
      content: '';
      display: block;
      width: 50%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.primary};
      margin-top: 0.5rem;
      margin-left: auto;
      margin-right: auto;
      transition: width 0.3s ease-in-out;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  .hero-para {
    margin-top: 2rem;
    font-size: 1.5rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.text};
    padding-left: 2rem;
    &:first-of-type {
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 600px) {
    .grid-two-column {
      grid-template-columns: 1fr;
    }
    .section-hero-data {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .section-hero-image {
      display: none;
    }
  }
`;

export default About;
