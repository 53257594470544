import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from './Navbar';
import styled from 'styled-components';

export const Header = () => {
  return (
    <MainHeader>
      <NavLink to="/">
        <img src="./images/logofinal.png" alt="logo" className="logo" />
      </NavLink>
      <Navbar />
    </MainHeader>
  );
};

const MainHeader = styled.header`
  padding: 0 4.8rem;
  height: 10rem;
  background-color: ${({ theme }) => theme.colors.bg};

  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 130px;
    height: auto;
    margin-left: 65px;
  }
    @media only screen and (max-width: 600px) {
    .logo {
    width: 130px;
    height: auto;
    margin-left: 2px;
  }
    }
`;

export default Header;
