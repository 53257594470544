import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import {
  FaCog,
  FaChartLine,
  FaDatabase,
  FaCloud,
  FaMobileAlt,
  FaLock,
  FaTools,
  FaHandshake,
} from "react-icons/fa";

const servicesData = [
  {
    icon: <FaCog />,
    title: "Consulting",
    description: "Expert consulting services to help you navigate complex business challenges.",
  },
  {
    icon: <FaChartLine />,
    title: "Analytics",
    description: "Data-driven insights to help you make informed business decisions.",
  },
  {
    icon: <FaDatabase />,
    title: "Database Management",
    description: "Efficient management of your databases to ensure optimal performance and security.",
  },
  {
    icon: <FaCloud />,
    title: "Cloud Solutions",
    description: "Scalable cloud solutions to support your business's growth and agility.",
  },
  {
    icon: <FaMobileAlt />,
    title: "Mobile Development",
    description: "Creating responsive and feature-rich mobile applications.",
  },
  {
    icon: <FaLock />,
    title: "Cybersecurity",
    description: "Comprehensive security solutions to protect your digital assets.",
  },
  {
    icon: <FaTools />,
    title: "IT Support",
    description: "Providing round-the-clock IT support and maintenance.",
  },
  {
    icon: <FaHandshake />,
    title: "Customer Relations",
    description: "Enhancing customer engagement and satisfaction.",
  },
];

const OurServices = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Check screen size on initial render
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <ServicesWrapper>
      <h2 className="services-heading">Our Services</h2>
      {isMobile ? (
        <Slider {...sliderSettings} className="services-slider">
          {servicesData.map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-icon">{service.icon}</div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="services-container">
          {servicesData.map((service, index) => (
            <div key={index} className="service-card">
              <div className="service-icon">{service.icon}</div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      )}
    </ServicesWrapper>
  );
};

const ServicesWrapper = styled.section`
  padding: 5rem 10rem;
  background-color: #f9f9f9;
  text-align: center;
   margin-left: 20px;
  .services-heading {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(24, 24, 29);
    margin-bottom: 5rem;
  }

  .services-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }

  .services-slider {
    .slick-dots {
      bottom: -15px;
    padding-right: 6rem;

      li {
        button {
          &::before {
            color: #007bff;
          }
        }

        &.slick-active button::before {
          color: #0056b3;
        }
      }
    }
  }

  .service-card {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }

  .service-icon {
    font-size: 3rem;
    font-weight: 600;
    color: #007bff;
    margin-bottom: 1rem;
  }

  .service-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #333;
  }

  .service-description {
    font-size: 1.5rem;
    color: #666;
  }

  @media (max-width: 768px) {
    padding: 2rem;
    
  background-color: #f9f9f9;
  text-align: center;
   margin-left: 0px;
    .services-heading {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    .services-container {
      display: none; /* Hide grid layout on mobile */
    }

    .services-slider {
      display: block; 
      padding-left: 3rem;
      padding-right: 2rem;
    }
  }
`;

export default OurServices;
