import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa';

const Nav = styled.nav`
  .navbar-list {
    display: flex;
    gap: 4.8rem;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 5rem;

    li {
      list-style: none;

      .navbar-link {
        &:link,
        &:visited {
          display: inline-block;
          font-size: 1.8rem;
          font-weight:400;
          text-decoration: none;
          color: ${({ theme }) => theme.colors.black};

          &:hover,
          &:active {
            color: ${({ theme }) => theme.colors.helper};
            transition: color 0.3s linear;
          }
        }
      }
    }
  }

  .nav-btn {
    display: none;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
  }

  @media only screen and (max-width: 768px) {
    .nav-btn {
      display: block;
    }

    .navbar-list {
      position: fixed;
      top: 0;
      left: 0;
      height: 30vh;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      background-color: ${({ theme }) => theme.colors.white};
      transition: transform 0.3s ease-in-out;
      transform: translateY(-100%);
      z-index: 1000;
    }

    .navbar-list.responsive_nav {
      transform: translateY(0);
    }

    .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
  }
`;

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav');
  };

  return (
    <Nav>
      <div className="menuIcon">
        <ul className="navbar-list" ref={navRef}>
          <li>
            <NavLink className="navbar-link" to="/" onClick={showNavbar}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink className="navbar-link" to="/about" onClick={showNavbar}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink className="navbar-link" to="/services" onClick={showNavbar}>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink className="navbar-link" to="/contact" onClick={showNavbar}>
              Contact
            </NavLink>
          </li>
         
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </ul>
      </div>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </Nav>
  );
}

export default Navbar;
