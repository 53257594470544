import React, { useRef } from 'react';
import styled from 'styled-components';

const SolutionsHeader = () => {
  const interactiveGamesRef = useRef(null);
  const engagingGamesRef = useRef(null);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <HeaderWrapper>
      <div className="left-side">
        <h2 className="category-title">Our Technology Solutions</h2>
        <p className="intro-text">
          Explore our wide range of tech solutions to enhance your brand activation, boost customer engagement, and drive business growth.
          <br />
          <span
            className="scroll-link"
            onClick={() => scrollToSection(interactiveGamesRef)}
          >
            Interactive Games
          </span>
          &nbsp;|&nbsp;
          <span
            className="scroll-link"
            onClick={() => scrollToSection(engagingGamesRef)}
          >
            Engaging Games
          </span>
        </p>
      </div>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.section`
  display: flex;
  justify-content: flex-start; /* Align text to the left */
  align-items: center;
  gap: 3rem;
  flex-wrap: wrap; /* Ensure responsive behavior */
  margin-bottom: 3rem;
  background: url('/images/techhhh.jpg') no-repeat center center;
  background-size: cover;
  height: 400px; /* Adjust the height as per your design */
  color: white;
  padding: 2rem;
  padding-right: 93px;

  .left-side {
    flex: 1;
    max-width: 50%;
    text-align: left; /* Align text to the left */
    z-index: 1; /* Ensure text appears above the image */
  }

  .category-title {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(24, 24, 29);
    padding-left: 5px;
    margin-bottom: 1rem;
    letter-spacing: 2px;
  }

  .intro-text {
    padding: 1rem;
    padding-left: 100px;
    color: ${({ theme }) => theme.colors.text};
    margin-top: 1.5rem;
    margin-bottom: 2.4rem;
    max-width: 60rem;
    font-size: 1.5rem;
    line-height: 1.6;
    font-family: 'Raleway', sans-serif;
  }

  /* Scroll link styles */
  .scroll-link {
    color: ${({ theme }) => theme.colors.link};
    font-weight: 600;
    cursor: pointer;
    font-size: 2rem;
    text-decoration: underline;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center; /* Center text for smaller screens */
    gap: 1rem;
    height: auto; /* Allow height to adjust based on content */
    padding: 2rem;
   

    .left-side {
      max-width: 100%;
      padding: 0; /* Remove additional padding for smaller screens */
    }

    .category-title {
      font-size: 2rem;
      padding-left: 0; /* Align text in the center */
    }

    .intro-text {
      font-size: 1.2rem;
      padding: 0;
      margin: 0 auto;
      line-height: 1.4;
    }

    .scroll-link {
      font-size: 1.3rem;
    }
  }

  @media only screen and (max-width: 480px) {
    .category-title {
      font-size: 3rem;
    }

    .intro-text {
          font-size: 1.5rem;
    padding-left: 3rem;
    padding-right: 1rem;
    padding-top: 2rem;
    }

    .scroll-link {
      font-size: 2rem;
        padding-right: 6rem;
    }
  }
`;

export default SolutionsHeader;
