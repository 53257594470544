import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import '@fontsource/work-sans'; // Ensure font is applied globally
import { FaBullhorn, FaBoxOpen, FaUsers, FaLightbulb } from 'react-icons/fa';

const Technologysolutions = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <Wrapper>
    <div className="container">
      <section className="interactive-games">
        <h2 className="category-title">Interactive Games</h2>
  
        {/* Luck Based Games Section */}
        <div className="game-category luck-based-games">
          <div className="left-section">
            <h3 className="section-title">Luck Based Games</h3>
            <p className="description">
              Luck-based games are a thrilling form of entertainment where outcomes rely
              on chance rather than skill. These games offer an exciting and
              unpredictable experience, where players can win big with a single lucky
              turn. Whether it’s spinning a wheel, drawing a card, or rolling dice,
              luck-based games add a sense of excitement and fun.
            </p>
          </div>
          <div className="right-section">
            <Slider {...settings}>
              <GameCard title="Spin the Wheel" image="/images/spinfinal.jpg" reverse={false} />
              <GameCard title="Rolling Roulette" image="/images/rollingfinal.jpg" reverse={false} />
              <GameCard title="Scratch Card" image="/images/scard.png" reverse={false} />
              <GameCard title="Roll a Dice" image="/images/dicefinal.jpg" reverse={false} />
            </Slider>
          </div>
        </div>
  
        {/* Skill Based Games Section */}
        <div className="game-category skill-based-games">
        <div className="right-section">
            <Slider {...settings}>
              <GameCard title="Slice It" image="/images/slice.jpg" reverse={false} />
              <GameCard title="Puzzle" image="/images/puzzle.jpg" reverse={false} />
              <GameCard title="Hit the Target" image="/images/hit.jpg" reverse={false} />
              <GameCard title="Shoot It" image="/images/shoot.jpg" reverse={false} />
            </Slider>
          </div>
          <div className="left-section">
            <h3 className="section-title">Skill Based Games</h3>
            <p className="description">
              Skill-based games challenge players' abilities, precision, and reflexes. 
              These games reward effort and mastery, offering an engaging experience 
              that encourages practice and improvement. Whether it’s solving puzzles, 
              hitting targets, or slicing through obstacles, skill-based games provide 
              an opportunity to showcase and refine your talents.
            </p>
          </div>
          
        </div>
  
        {/* Knowledge Based Games Section */}
        <div className="game-category knowledge-based-games">
          <div className="left-section">
            <h3 className="section-title">Knowledge Based Games</h3>
            <p className="description">
              Knowledge-based games test your intellect, trivia expertise, and cognitive skills. 
              These games are perfect for those who love to learn, solve challenges, and engage 
              in thought-provoking activities. From quizzes to image guessing and word puzzles, 
              these games make learning both fun and rewarding.
            </p>
          </div>
          <div className="right-section">
            <Slider {...settings}>
              <GameCard title="Guess the Image" image="/images/guesstheimage.jpg" reverse={false} />
              <GameCard title="Quiz" image="/images/quiz (1).jpg" reverse={false} />
              <GameCard title="Survey" image="/images/survey.jpg" reverse={false} />
              <GameCard title="Guess the Word" image="/images/guessword.jpg" reverse={false} />
            </Slider>
          </div>
        </div>
      </section>
    
  

        {/* Engaging Campaigns Section */}
        <section className="engaging-campaigns">
          <h2 className="category-title">Engaging Campaigns</h2>
          

          <div className="campaign-categories">
            <CampaignCard
              title="Business Driving Campaigns"
              description="Drive your channel partners to engage more in business and deliver superior results."
              icon={<FaBullhorn />}
            />
            <CampaignCard
              title="New Product Introduction"
              description="Capture attention of customers with your new products and get ahead in your sales plan."
              icon={<FaBoxOpen />}
            />
            <CampaignCard
              title="Customer Acquisition Campaigns"
              description="Focus on revenue growth from increased purchase intent, basket size, and lower acquisition costs."
              icon={<FaUsers />}
            />
            <CampaignCard
              title="Awareness Campaigns"
              description="Stand out from your competitors with an interactive campaign and increase time spent with your brand."
              icon={<FaLightbulb />}
            />
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const GameCard = ({ title, image, description, reverse }) => {
  return (
    <CardWrapper>
      <ContentWrapper reverse={reverse}>
        <TextWrapper>
          {description && <p className="game-description">{description}</p>}
        </TextWrapper>
        <img src={image} alt={title} />
      </ContentWrapper>
      <p className="title">{title}</p>
    </CardWrapper>
  );
};

const CampaignCard = ({ title, description, icon }) => {
  return (
    <CardWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <h3 className="section-title">{title}</h3>
      <p className="card-description">{description}</p>
    </CardWrapper>
  );
};

const Wrapper = styled.section`
  padding: 4rem 2rem;
  background-color: #f7f7f7;
  font-family: 'Work Sans', sans-serif;

  .category-title {
    font-size: 3rem;
    font-weight: bold;
    color: rgb(24, 24, 29);
    margin-bottom: 3rem;
    text-align: center;
  }

  .interactive-games {
    margin-bottom: 4rem;
  }

  .game-category {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 4rem;
    background-color: white;
     margin-left: 4.5rem;
    margin-right: 3rem;
    border-radius: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 4rem;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
       margin-left: 3rem;
        padding-top: 1rem;
    }
  }

  .left-section {
    flex: 1;
    max-width: 60%;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: left;
    }
  }

  .right-section {
    flex: 2;
    max-width: 20%;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .description {
    font-size: 1.5rem;
    line-height: 1.8;
    color: #333;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .section-title {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color:rgb(0, 0, 0);

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .campaign-categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      width: 314px;
      margin-left: -18px;
    }
  }

  .engaging-campaigns {
    padding-top: 0rem;
    margin-bottom: 4rem;
    padding-left: 5rem;
    padding-right: 3rem;
    margin-bottom: 4rem;
  }

  .game-description {
    font-size: 1.5rem;
    padding: 0 20px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      padding: 0;
    }
  }

  .card-description {
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
`;

const CardWrapper = styled.div`
  text-align: left;
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  .title {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 1rem;

    @media (max-width: 768px) {
      font-size: 1.3rem;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  img {
    max-width: 150px;
    height: auto;
    border-radius: 8px;

    @media (max-width: 768px) {
      max-width: 100px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  font-size: 1.5rem;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 1.2rem;
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #1170bf;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;


export default Technologysolutions;
